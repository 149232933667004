'use strict';

/*******************************************************************************************/
class UxrEeLabel extends React.Component {
	constructor(props) {
		super(props);
		//this.state = {};
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		return (
			<div {...this.props.htmlProps}>
				<label>{this.props.content}</label>
			</div>
		);
	}
	
} //class
